/**
 * Global style
 *
 * @param {Object} theme a theme
 * @returns {Object} style object
 */
export default {
  centeredCtn: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto'
  },
  bodyContainer: {
    height: 'calc(100% - 64.237px) !important',
    overflowX: 'auto'
  },
  conditions: {
    textAlign: 'center',
    width: '400px',
    margin: 'auto',
    color: 'rgb(243, 243, 243)',
    fontSize: '16px',
    marginTop: '10px',
    marginBottom: '10px'
  },
  form: {
    border: '1px solid rgb(234, 238, 251)',
    borderRadius: '4px',
    padding: '10px',
    height: 'auto',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04)',
    width: '450px',
    margin: 'auto',
    textAlign: 'center'
  },
  accountForm: {
    margin: 'auto',
    marginTop: '40px',
    border: '1px solid rgb(234, 238, 251)',
    borderRadius: '4px',
    padding: '24px',
    width: '450px'
  },
  loginForm: {
    border: '1px solid rgb(234, 238, 251)',
    borderRadius: '4px',
    padding: '10px',
    height: 'auto',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04)',
    width: '450px',
    margin: 'auto',
    textAlign: 'center'
  },
  floatRight: {
    float: 'right'
  },
  formLabel: {
    width: '120px',
    padding: '5px 12px 11px 0 !important'
  },
  formInput: {
    marginLeft: '120px'
  },
  title: {
    fontSize: '1.1em',
    textAlign: 'center',
    margin: 'auto',
    color: 'rgb(50, 65, 87)',
    fontWeight: 'bold'
  },
  whiteBackground: {
    width: '240px',
    padding: '24px',
    border: '1px solid rgb(234, 238, 251)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04)'
  },
  dashboardH2Titles: {
    color: '#324157',
    fontSize: '22px',
    textAlign: 'center'
  },
};
