import globalStyles from './global.css';

const { loginForm, bodyContainer } = globalStyles;
const styles = () => {
  return {
    loginForm,
    bodyContainer,
    loginContainer: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
      userSelect: 'none'
    },
    textField: {
      marginTop: '30px'
    },
    accountContainer: {
      display: 'grid',
      width: '250px',
      margin: 'auto'
    },
    accountHeader: {
      border: '1px solid rgb(234, 238, 251)',
      borderRadius: '4px',
      padding: '10px',
      height: 'auto',
      boxShadow:
        '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04)',
      width: '450px',
      margin: 'auto',
      textAlign: 'center',
      marginTop: '50px',
      backgroundColor: '#324157',
      display: 'block'
    },
    accountFooter: {
      border: '1px solid rgb(234, 238, 251)',
      borderRadius: '4px',
      padding: '10px',
      height: 'auto',
      backgroundColor: '#324157',
      boxShadow:
        '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04)',
      width: '450px',
      margin: 'auto',
      marginBottom: '5px',
      textAlign: 'center',
      color: 'rgb(243, 243, 243)',
      fontSize: '14px',
      display: 'grid'
    },
    iconPortrait: {
      width: '100px',
      height: '100px',
      color: 'rgb(243, 243, 243)'
    },
    buttonSend: {
      marginTop: '50px',
      marginBottom: '50px',
      width: '200px',
      margin: 'auto'
    },
    loginTitle: {
      color: 'rgb(243, 243, 243)',
      height: 'auto',
      fontSize: '24px'
    },
    spanSignUp: {
      textDecoration: 'underline',
      cursor: 'pointer',
      marginLeft: '5px'
    }
  };
};

export default styles;
