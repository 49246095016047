import globalStyles from './global.css';

const { accountForm } = globalStyles;
const styles = () => {
  return {
    accountForm,
    supportContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 64.237px)'
    },
    supportSection: {
      border: '1px solid rgb(234, 238, 251)',
      borderRadius: '4px',
      padding: '10px',
      height: 'auto',
      backgroundColor: '#fff',
      boxShadow:
        '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04)',
      width: '517.5px',
      margin: 'auto',
      marginBottom: '5px',
      marginTop: '10px',
      textAlign: 'center'
    },
    supportSectionTitle: {
      fontSize: '20px',
      color: '#324157'
    },
    textArea: {
      minHeight: '8em',
      minWidth: '100%',
      maxWidth: '100%'
    },
    contactForm: {
      marginTop: 0,
      width: '490px',
      backgroundColor: '#fff',
      boxShadow:
        '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04)'
    },
    notifError: {
      marginBottom: '20px'
    },
    centerInfo: {
      textAlign: 'center'
    },
    conditionsSupport: {
      textAlign: 'center',
      width: '400px',
      margin: 'auto',
      color: 'grey',
      fontSize: '16px',
      marginTop: '10%',
      marginBottom: '10px'
    },
    container: {
      margin: 'auto'
    }
  };
};

export default styles;
