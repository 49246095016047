import BaseAPI from './BaseAPI';

export default class EmailAPI extends BaseAPI {
  static validate(token) {
    return this.execute('post', '/emails/validate', { data: { token } });
  }

  static sendResetPassword(username, token) {
    return this.execute('post', '/emails/send_reset', {
      data: { username, token }
    });
  }

  static verifyToken(token) {
    return this.execute('post', '/password/reset', { data: { token } });
  }

  static send() {
    return this.execute('post', '/emails/send');
  }
}
