import React, { useContext } from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { UserContext } from '../contexts/UserContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, isLoggedIn } = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <>
            {user.isValidated ? (
              <Component {...props} />
            ) : (
              <>
                <Box
                  boxShadow={3}
                  style={{
                    width: '30%',
                    margin: 'auto',
                    height: '100px',
                    marginTop: '17%',
                    fontSize: '20px',
                    textAlign: -'webkit-center',
                    padding: '14px',
                    backgroundColor: 'white'
                  }}
                >
                  You must validate your account to use this functionnality. A
                  mail has been sent to your inbox to help active it.
                  <Link to="/profile">
                    <Button
                      variant="contained"
                      style={{
                        width: '-webkit-fill-available',
                        marginTop: '20px'
                      }}
                    >
                      Go to Profile
                    </Button>
                  </Link>
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
