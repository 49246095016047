/* eslint-disable class-methods-use-this */
import 'element-theme-default';
import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { loadReCaptcha } from 'react-recaptcha-v3';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import Navigation from './Navigation';
import Configuration from '../helpers/configuration';

import '../css/Global.css';
import '../index.css';
import UserProvider from '../contexts/UserContext';

const history = createBrowserHistory();
if (process.env.NODE_ENV === 'production') {
  history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
}

class App extends Component {
  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.pageview(window.location.pathname);
    }

    loadReCaptcha(Configuration.CAPTCHA_TOKEN);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>
            apigrow, business model to api documentation generator
          </title>
          <meta
            name="description"
            content="graphically describe your business objects and their attributes, and the corresponding Rest APIs documentation is automatically generated by apigrow"
          />
          <meta
            name="keywords"
            content="swagger editor,swagger documentation,api documentation generator,automated api documentation,swagger documentation generator,open api documentation generator,Rest APIs documentation"
          />
        </Helmet>
        <UserProvider>
          <Router basename={process.env.PUBLIC_URL} history={history}>
            <Navigation history={history} />
          </Router>
        </UserProvider>
      </div>
    );
  }
}

export default App;
