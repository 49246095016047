import globalStyles from './global.css';

const {
  centeredCtn,
  conditions,
  accountForm,
  formLabel,
  formInput,
  bodyContainer,
  form
} = globalStyles;

const styles = () => {
  return {
    centeredCtn,
    conditions,
    accountForm,
    formLabel,
    formInput,
    bodyContainer,
    form,
    notifError: {
      marginBottom: '15px'
    },
    subscribeContainer: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
      userSelect: 'none'
    },
    subscribeHeader: {
      border: '1px solid rgb(234, 238, 251)',
      borderRadius: '4px',
      padding: '10px',
      height: 'auto',
      boxShadow:
        '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04)',
      width: '450px',
      margin: 'auto',
      textAlign: 'center',
      marginTop: '50px',
      backgroundColor: '#324157',
      display: 'block'
    },
    subscribeFooter: {
      border: '1px solid rgb(234, 238, 251)',
      borderRadius: '4px',
      padding: '10px',
      height: 'auto',
      backgroundColor: '#324157',
      boxShadow:
        '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04)',
      width: '450px',
      margin: 'auto',
      marginBottom: '5px',
      textAlign: 'center',
      color: 'rgb(243, 243, 243)',
      fontSize: '14px'
    },
    subscribeTitle: {
      color: 'rgb(243, 243, 243)',
      height: 'auto',
      fontSize: '24px'
    },
    iconPortrait: {
      width: '100px',
      height: '100px',
      color: 'rgb(243, 243, 243)'
    },
    spanLogIn: {
      textDecoration: 'underline',
      cursor: 'pointer',
      marginLeft: '5px'
    },
    buttonSend: {
      marginTop: '20px',
      marginBottom: '20px',
      width: '200px',
      margin: 'auto'
    },
    accountContainer: {
      display: 'grid',
      width: '250px',
      margin: 'auto'
    }
  };
};

export default styles;
