/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, Suspense, lazy, useState } from 'react';
import { Route, Link, withRouter, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  Typography,
  Popper,
  Fade,
  Paper,
} from '@material-ui/core';
import PrivateRoute from './PrivateRoute';
import RestrictedRoute from './RestrictedRoute';
import GraviteeLogo from '../assets/logo_gravitee.png';
import Login from './account/Login';
import Registration from './account/Registration';
import ValidatePassword from './email/ValidatePassword';
import Support from './base/Support';
import FeaturesInformation from './teams/FeaturesInformation';
import { UserContext } from '../contexts/UserContext';
import Configuration from '../helpers/configuration';

const MindMapView = lazy(() => import('./base/MindmapView'));
const MindMapFrozenView = lazy(() =>
  import('./mindmap/frozen/FrozenMindmapView')
);
const Dashboard = lazy(() => import('./base/Dashboard'));
const Home = lazy(() => import('./base/Home'));
const Profile = lazy(() => import('./account/Profile'));
const ValidationEmail = lazy(() => import('./email/ValidateEmail'));
const ResetPassword = lazy(() => import('./email/ResetPassword'));
const OrganizationView = lazy(() => import('./base/OrganizationView'));
const TeamView = lazy(() => import('./base/TeamView'));

const useStyles = makeStyles(theme => {
  return {
    navbar: {
      zIndex: 0,
      userSelect: 'none',
      borderRadius: '0',
    },
    logoApizr: {
      height: '5vh',
      float: 'left',
      padding: '0.5vh 0 0 0.5rem',
    },
    logoGravitee: {
      float: 'left',
      paddingLeft: '0.6rem',
    },
    navigationContainer: {
      height: '100%',
      position: 'fixed',
      width: '100%',
      backgroundColor: 'rgb(243, 243, 243)',
    },
    navItem: {
      fontSize: '16px',
    },
    progress: {
      margin: 'auto',
      display: 'block',
      marginTop: '20%',
    },
    typography: {
      padding: theme.spacing(2),
    },
    popperSignUp: {
      padding: theme.spacing(1),
      marginLeft: '2%',
      marginBottom: '2%',
    },
    popperCancel: {
      padding: theme.spacing(1),
      marginLeft: '58%',
      marginBottom: '2%',
    },
  };
});

function Navigation() {
  const { isLoggedIn, logUserOut, user, teams } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popperOpen, setPopperOpen] = useState(false);
  const history = useHistory();

  const goToContact = () => {
    history.push('/support');
  };

  const goToSignUp = () => {
    setPopperOpen(false);
    history.push('/signup');
  };

  const handlePopperOpen = event => {
    setAnchorEl(event.currentTarget);
    setPopperOpen(!popperOpen);
  };

  const handlePopperClose = () => {
    setPopperOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const logo = Configuration.GRAVITEE_ENABLED ? (
    <a href={`${Configuration.GRAVITEE_MANAGEMENT_UI_URL}/#!/apis/`}>
      <img
        className={`${classes.logoGravitee} logo`}
        src={GraviteeLogo}
        alt="Gravitee Logo"
      />
    </a>
  ) : (
    <Link to="/">
      <img
        className={`${classes.logoApizr} logo`}
        src="/images/logo.png"
        alt="Apizr Logo"
      />
    </Link>
  );

  const guestMenu = (
    <ul
      className={`${classes.navbar} el-menu el-menu--horizontal el-menu--dark`}
    >
      {logo}
      <li className="el-menu-item">
        <div
          className="link nav-links-spacing"
          onClick={event => handlePopperOpen(event)}
        >
          Dashboard
        </div>
      </li>
      {!Configuration.GRAVITEE_ENABLED && (
        <>
          <li className="el-menu-item">
            <div
              className="link nav-links-spacing"
              onClick={() => setOpen(true)}
            >
              Enterprise features
            </div>
          </li>
          <Tooltip
            arrow
            title="Quick video tutorials"
            enterDelay={500}
            leaveDelay={200}
          >
            <li className="el-menu-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/playlist?list=PLW5s1w77V78oapuZMfbBnKzf57YFe_zY_"
                className="nav-links-spacing"
                style={{ alignItems: 'center' }}
              >
                Tutorials
              </a>
            </li>
          </Tooltip>
        </>
      )}
      <li className={`${classes.navItem} el-menu-item right`}>
        <Link to="/login">
          <div className="link nav-links-spacing">Log in</div>
        </Link>
      </li>
      <li className={`${classes.navItem} el-menu-item right`}>
        <Link to="/support">
          <div className="link nav-links-spacing">Contact us</div>
        </Link>
      </li>
    </ul>
  );

  const loggedMenu = (
    <ul
      className={`${classes.navbar} el-menu el-menu--horizontal el-menu--dark`}
    >
      {logo}
      <li className="el-menu-item">
        <Link to="/dashboard">
          <Tooltip
            arrow
            title="Manage / Select your Models"
            enterDelay={500}
            leaveDelay={200}
          >
            <div className="link nav-links-spacing">Dashboard</div>
          </Tooltip>
        </Link>
      </li>
      <li className="el-menu-item">
        <Link to="/organization">
          <Tooltip
            arrow
            title="Manage / Select your Settings"
            enterDelay={500}
            leaveDelay={200}
          >
            <div className="link nav-links-spacing">Settings</div>
          </Tooltip>
        </Link>
      </li>
      {!Configuration.GRAVITEE_ENABLED && (
        <>
          {// User has a team other than its personnal one
          teams.length > 1 && (
            <li className="el-menu-item">
              <Link to="/team">
                <Tooltip
                  arrow
                  title="Manage / Select your Team(s)"
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <div className="link nav-links-spacing">Teams</div>
                </Tooltip>
              </Link>
            </li>
          )}
          <li className="el-menu-item">
            <div
              className="link nav-links-spacing"
              onClick={() => setOpen(true)}
            >
              Enterprise features
            </div>
          </li>
          <Tooltip
            arrow
            title="Quick video tutorials"
            enterDelay={500}
            leaveDelay={200}
          >
            <li className="el-menu-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/playlist?list=PLW5s1w77V78oapuZMfbBnKzf57YFe_zY_"
                className="nav-links-spacing"
                style={{ alignItems: 'center' }}
              >
                Tutorials
              </a>
            </li>
          </Tooltip>
          <li className={`${classes.navItem} el-menu-item right`}>
            <div
              className="link nav-links-spacing"
              onClick={() => {
                logUserOut();
              }}
            >
              Log Out
            </div>
          </li>
          <li className={`${classes.navItem} el-menu-item right`}>
            <Link to="/profile">
              <div className="link nav-links-spacing">Profile</div>
            </Link>
          </li>
          <li className={`${classes.navItem} el-menu-item right`}>
            <Link to="/support">
              <div className="link nav-links-spacing">Contact us</div>
            </Link>
          </li>
        </>
      )}
    </ul>
  );

  // Display different menu if user is logged in
  const navigationMenu = isLoggedIn ? loggedMenu : guestMenu;

  return (
    <div className={classes.navigationContainer}>
      <Popper
        open={popperOpen}
        anchorEl={anchorEl}
        placement="bottom"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography className={classes.typography}>
                Create a free account to save and manage your models,
                <br />
                import or download specifications, etc.
              </Typography>
              <Button
                className={classes.popperCancel}
                onClick={() => handlePopperClose()}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                className={classes.popperSignUp}
                onClick={() => goToSignUp()}
                color="primary"
              >
                Sign up
              </Button>
            </Paper>
          </Fade>
        )}
      </Popper>
      {navigationMenu}
      <Suspense
        fallback={
          <div style={{ width: '100%', margin: 'auto' }}>
            <CircularProgress size={100} className={classes.progress} />
          </div>
        }
      >
        {/* Don't show the landing page when Gravitee integration is enabled */}
        {Configuration.GRAVITEE_ENABLED ? (
          <Route exact path="/" component={props => <Dashboard {...props} />} />
        ) : (
          <Route exact path="/" component={props => <Home {...props} />} />
        )}

        <PrivateRoute
          path="/dashboard"
          component={props => <Dashboard {...props} />}
        />

        <RestrictedRoute
          path="/profile"
          component={props => <Profile {...props} />}
        />

        <PrivateRoute
          exact
          path="/organization"
          component={props => <OrganizationView {...props} />}
        />

        <PrivateRoute
          exact
          path="/team"
          component={props => <TeamView {...props} />}
        />

        <Route
          exact
          path="/tree"
          component={props => <MindMapView {...props} />}
        />

        <PrivateRoute
          exact
          path="/tree-frozen"
          component={props => <MindMapFrozenView {...props} />}
        />

        <PrivateRoute
          exact
          path="/tree-read-only"
          component={props => <MindMapFrozenView {...props} />}
        />

        <Route
          exact
          path="/support"
          component={props => <Support {...props} />}
        />

        <Route
          exact
          path="/signup"
          component={props => <Registration {...props} />}
        />

        <Route exact path="/login" component={props => <Login {...props} />} />

        <Route
          exact
          path="/email"
          component={props => <ValidationEmail {...props} user={user} />}
        />

        <Route
          exact
          path="/reset"
          component={props => <ResetPassword {...props} />}
        />

        <Route
          exact
          path="/reset/:token"
          component={props => <ValidatePassword {...props} />}
        />
      </Suspense>

      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <>
          <DialogContent style={{ maxWidth: '700px' }}>
            <Typography
              className={classes.titleContact}
              component="h6"
              variant="h6"
            >
              Personal plan is <strong>free</strong> to use, ideal for an
              independent developer or small digital agency.
            </Typography>
            <Typography
              className={classes.titleContact}
              component="h6"
              variant="h6"
            >
              The Team plan allows collaboration between business and technical
              people.
            </Typography>
            <Typography
              className={classes.titleContact}
              component="h6"
              variant="h6"
            >
              The Enterprise plan is well suited for large scale organizations.
            </Typography>

            <FeaturesInformation />

            <Typography
              className={classes.textContact}
              component="h6"
              variant="h6"
            >
              Interested by these features? Contact us to upgrade.
            </Typography>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.buttonContact}
              onClick={() => goToContact()}
            >
              Contact us
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </div>
  );
}

export default withRouter(Navigation);
