/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import { ReCaptcha } from 'react-recaptcha-v3';
import { Notification } from 'element-react';
import { withStyles } from '@material-ui/core/styles';
import Joi from '@hapi/joi';
import MessageAPI from '../../api/MessageAPI';
import styles from '../../styles/Support.css';
import { UserContext } from '../../contexts/UserContext';
import Configuration from '../../helpers/configuration';

const schema = Joi.object().keys({
  username: Joi.string()
    .label('Email')
    .email({ tlds: false })
    .required()
});

class Support extends Component {
  static contextType = UserContext;

  state = {
    error: '',
    invalidForm: true,
    username: '',
    message: '',
    ReCaptchaToken: ''
  };

  captchaValidation = ReCaptchaToken => {
    this.setState({ ReCaptchaToken });
  };

  handleChanges = (type, value) => {
    this.setState({ [type]: value }, this.checkForm);
  };

  checkForm = () => {
    const { username } = this.state;
    if (this.context.isLoggedIn) {
      this.setState({
        invalidForm: false,
        error: null
      });
      return;
    }

    const data = {
      username
    };

    const result = schema.validate(data);
    this.setState({
      invalidForm: !!result.error,
      error: result.error ? result.error.details[0].message : null
    });
  };

  sendMessage = () => {
    const { message, ReCaptchaToken } = this.state;
    let { username } = this.state;
    const lcs = this.context.user;
    username = !username && lcs.username ? lcs.username : username;
    MessageAPI.send({
      username,
      message,
      token: ReCaptchaToken
    })
      .then(() => {
        const { history } = this.props;
        Notification({
          title: 'Success',
          message: 'Message sent',
          type: 'success'
        });
        history.push('/');
      })
      .catch(() => {
        Notification.error({
          title: 'Error',
          message:
            'There was a problem sending your message. Please refresh and try again'
        });
      });
  };

  render() {
    const { classes } = this.props;
    const { error, invalidForm } = this.state;
    const { isLoggedIn } = this.context;

    let errorBanner;
    if (error) {
      errorBanner = (
        <div className={`${classes.notifError} el-alert el-alert--warning`}>
          <div className="el-alert__content">
            <span className="el-alert__title">{error}</span>
          </div>
        </div>
      );
    }

    const form = (
      <form className={`${classes.accountForm} ${classes.contactForm}`}>
        {errorBanner}
        <div className="el-input">
          {/* Ask for an email if the user is not logged */}
          {!isLoggedIn && (
            <div className="el-form-item">
              <span className="el-form-item__label">Email</span>
              <div className="el-form-item__content">
                <input
                  placeholder="John@example.com"
                  type="email"
                  className="el-input__inner"
                  autoComplete="on"
                  name="email"
                  onChange={e => {
                    this.handleChanges('username', e.target.value);
                  }}
                />
              </div>
            </div>
          )}
          <div className="el-form-item">
            <div className="el-form-item__content">
              <textarea
                placeholder="Type your message here..."
                className={`${classes.textArea} el-input__inner`}
                autoComplete="off"
                name="message"
                onChange={e => {
                  this.handleChanges('message', e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="el-form-item">
          <div className="el-form-item__content">
            <button
              disabled={invalidForm}
              className={`el-button el-button--primary ${
                invalidForm ? 'is-disabled' : ''
              }`}
              type="button"
              onClick={() => {
                this.sendMessage();
              }}
            >
              Send
            </button>
          </div>
        </div>
        <div className={`${classes.notifError} ${classes.centerInfo} el-alert el-alert--info`}>
          <div className="el-alert__content">
            <span className="el-alert__title">
              Ce site est protégé par reCAPTCHA et Google
              <br />
              <a href="https://policies.google.com/privacy">Politique de confidentialité</a> et&nbsp;
              <a href="https://policies.google.com/terms">Conditions d&apos;utilisation</a>
            </span>
          </div>
        </div>
      </form>
    );

    return (
      <div className={`${classes.supportContainer}`}>
        <div className={classes.container}>
          <div className={classes.supportSection}>
            <span className={classes.supportSectionTitle}>Message</span>
          </div>
          {form}
          <ReCaptcha
            sitekey={Configuration.CAPTCHA_TOKEN}
            verifyCallback={this.captchaValidation}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Support);
