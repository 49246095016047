import BaseAPI from './BaseAPI';

export default class OrganizationAPI extends BaseAPI {
  static getOrganization(organizationId) {
    return this.execute('get', `/organizations/${organizationId}`);
  }

  static getMyOwnOrganizations() {
    return this.execute('get', '/users/me/ownOrganizations');
  }

  static createOrganization(name, slug) {
    return this.execute('post', '/organizations', {
      data: { name, slug }
    });
  }

  static deleteOrganization(organizationId) {
    return this.execute('delete', `/organizations/${organizationId}`);
  }
}
