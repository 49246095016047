import React, { Component, createContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserAPI from '../api/UserAPI';
import AccountAPI from '../api/AccountAPI';
import TeamAPI from '../api/TeamAPI';
import OrganizationAPI from '../api/OrganizationAPI';

export const UserContext = createContext({
  user: { isValidated: false },
  isLoggedIn: false,
  teams: [],
  selectedTeam: '',
  organizations: [],
  ownOrganizations: [],
  getMyOwnOrganizations: () => {},
  getMyTeams: () => {},
  registerUser: () => {},
  logUserIn: () => {},
  logUserOut: () => {},
  setUser: () => {},
  setIsValidated: () => {},
  getCurrentUser: () => {}
});

export default class UserProvider extends Component {
  defaultState = {
    user: { isValidated: false },
    teams: [],
    selectedTeam: '',
    organizations: [],
    ownOrganizations: [],
    isLoggedIn: false
  };

  state = { ...this.defaultState, isLoading: true };

  componentDidMount() {
    this.getCurrentUser();
  }

  setIsValidated = isValidated => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        isValidated
      }
    }));
  };

  getCurrentUser = async () => {
    try {
      const user = await UserAPI.getCurrentUser();
      const teams = await TeamAPI.getMyTeams();
      const ownOrganizations = user.organizations
        .filter(orga => orga.isOwned)
        .map(({ id, slug, name, portal }) => ({
          id,
          slug,
          name,
          portal
        }));
  
      this.setState({
        user,
        isLoggedIn: true,
        isLoading: false,
        ownOrganizations,
        teams,
      })
    } catch(err) {
      this.setState({ ...this.defaultState, isLoading: false });
    };
  };

  getMyOwnOrganizations = () => {
    OrganizationAPI.getMyOwnOrganizations()
      .then(ownOrganizations => {
        this.setState({ ownOrganizations });
      })
      .catch(() => {
        this.setState({
          ownOrganizations: []
        });
      });
  };

  getMyTeams = () => {
    return TeamAPI.getMyTeams()
      .then(teams => {
        this.setState({ teams });
      })
      .catch(() => {
        this.setState({
          teams: []
        });
      });
  };

  logUserIn = (username, password, token) => {
    return AccountAPI.Login(username, password, token)
      .then(() => {
        return UserAPI.getCurrentUser();
      })
      .then(user => {
        this.setState({ user, isLoggedIn: true });
        return user;
      })
      .catch(() => {
        this.setState(this.defaultState);
        throw new Error(
          'Something went wrong during connection. Make sure to enter the right email and password'
        );
      });
  };

  logUserOut = () => {
    AccountAPI.Logout();
    this.setState(this.defaultState);
  };

  // eslint-disable-next-line class-methods-use-this
  async registerUser(firstname, lastname, username, password, reCaptchaToken) {
    try {
      return AccountAPI.Register(firstname, lastname, username, password, reCaptchaToken);
    }
    catch (err) {
      const message = (err.response && err.response.data && err.response.data.message) ||
        'Something went wrong during registration';
      throw new Error(message);
    }
  };

  render() {
    const {
      user,
      isLoggedIn,
      isLoading,
      teams,
      organizations,
      ownOrganizations
    } = this.state;
    const circularProgressStyle = {
      margin: 'auto',
      display: 'block',
      marginTop: '20%'
    };

    return (
      <UserContext.Provider
        value={{
          user,
          teams,
          organizations,
          ownOrganizations,
          isLoggedIn,
          registerUser: this.registerUser,
          logUserIn: this.logUserIn,
          logUserOut: this.logUserOut,
          setIsValidated: this.setIsValidated,
          setUser: this.setUser,
          getCurrentUser: this.getCurrentUser,
          getMyOwnOrganizations: this.getMyOwnOrganizations,
          getMyTeams: this.getMyTeams
        }}
      >
        {isLoading ? (
          <CircularProgress size={100} style={circularProgressStyle} />
        ) : (
          this.props.children
        )}
      </UserContext.Provider>
    );
  }
}
