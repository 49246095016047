import Configuration from '../helpers/configuration';

export default class BaseAPI {
  static execute(method, resource, params = {}, fullResponse = false) {
    const options = {
      method,
      url: resource,
      data: params.data,
      params: params.params
    };

    return Configuration.axiosClient(options).then(resp => {
      if (fullResponse) {
        return resp;
      }
      return resp.data;
    }).catch(err => {
      // If Gravitee integration is enabled and a request returns a 401 then redirect him to the Gravitee Portal URL
      if (
        Configuration.GRAVITEE_ENABLED
        && Configuration.GRAVITEE_MANAGEMENT_UI_URL
        && err.response.status === 401
      ) {
        window.location.replace(`${Configuration.GRAVITEE_MANAGEMENT_UI_URL}/#!/login?redirectUri=${window.location.href}`);
      }

      throw err;
    });
  }
}
