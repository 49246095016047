const styles = () => {
  return {
    container: {
      overflow: 'auto',
      width: '100%',
      height: '100%',
      display: 'flex'
    },
    card: {
      padding: '30px',
      width: 'fit-content',
      height: 'fit-content',
      margin: 'auto',
      textAlign: 'center',
      display: 'grid',
      boxShadow:
        '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 0px -1px rgba(0,0,0,0.12)',
      userSelect: 'none'
    },
    FormControl: {
      width: '300px',
      margin: 'auto'
    },
    subtitle: {
      width: '400px',
      margin: 'auto',
      marginBottom: '20px'
    },
    button: {
      margin: '10px'
    },
    textField: {
      margin: '10px'
    },
    spanGoBack: {
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: '14px'
    },
    iconChecked: {
      margin: '20px auto',
      width: '50px',
      height: '50px',
      color: 'aquamarine'
    },
    iconRefused: {
      margin: '20px auto',
      width: '50px',
      height: '50px',
      color: 'red'
    }
  };
};

export default styles;
