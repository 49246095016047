/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import Joi from '@hapi/joi';
import {
  Paper,
  Typography,
  TextField,
  Button,
  FormControl
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SendIcon from '@material-ui/icons/Send';
import { Notification } from 'element-react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/ResetPassword.css';
import AccountAPI from '../../api/AccountAPI';
import EmailAPI from '../../api/EmailAPI';

const schema = Joi.object().keys({
  password: Joi.string()
    .label('Password')
    .min(8)
    .required(),
  passwordValidation: Joi.string()
    .label('Password Confirmation')
    .valid(Joi.ref('password'))
    .required()
});

class ResetPassword extends PureComponent {
  state = {
    password: '',
    passwordValidation: '',
    invalidForm: true,
    error: '',
    passwordSend: false,
    tokenValidity: false
  };

  async componentDidMount() {
    const { history } = this.props;
    if (this.props.match.params.token) {
      await EmailAPI.verifyToken(this.props.match.params.token)
        .then(() => {
          this.setState({ tokenValidity: true });
        })
        .catch(() => {
          this.setState({ tokenValidity: false });
        });
    } else {
      // Redirect user if no token was provide.
      history.push('/');
    }
  }

  sendForm = e => {
    const { password } = this.state;
    e.preventDefault();
    AccountAPI.validateResetPassword(this.props.match.params.token, password)
      .then(() => {
        this.setState({ passwordSend: true });
      })
      .catch(() => {
        Notification.error({
          title: 'Error',
          message: 'An error occurred with the server.'
        });
      });
  };

  handleChanges = (type, value) => {
    this.setState({ [type]: value }, this.checkForm);
  };

  checkForm = () => {
    const { password, passwordValidation } = this.state;
    const data = {
      password,
      passwordValidation
    };

    const result = schema.validate(data);
    this.setState({
      invalidForm: !!result.error,
      error: result.error ? result.error.details[0].message : null
    });
  };

  goBack = () => {
    const { history } = this.props;
    history.push('/login');
  };

  render() {
    const { classes } = this.props;
    const { error, invalidForm, passwordSend, tokenValidity } = this.state;
    const errorState = !!error;
    return (
      <div className={classes.container}>
        <Paper className={classes.card}>
          {tokenValidity ? (
            passwordSend ? (
              <>
                <Typography variant="h5" component="h5">
                  Your password has been reset !
                </Typography>
                <CheckCircleIcon className={classes.iconChecked} />
                <span role="presentation" onClick={this.goBack}>
                  <span className={classes.spanGoBack}>Go back to login</span>
                </span>
              </>
            ) : (
              <>
                <Typography variant="h4" component="h1">
                  Reset your password
                </Typography>
                <Typography component="subtitle1" className={classes.subtitle}>
                  By validating your action you will change your password.
                </Typography>
                <FormControl className={classes.FormControl}>
                  <TextField
                    error={errorState}
                    label="Password"
                    name="password"
                    className={classes.textField}
                    type="password"
                    margin="normal"
                    variant="outlined"
                    helperText={error}
                    onChange={e => {
                      this.handleChanges('password', e.target.value);
                    }}
                  />
                  <TextField
                    error={errorState}
                    label="Password Confirmation"
                    name="passwordValidation"
                    className={classes.textField}
                    type="password"
                    margin="normal"
                    variant="outlined"
                    helperText={error}
                    onChange={e => {
                      this.handleChanges('passwordValidation', e.target.value);
                    }}
                  />
                  <Button
                    disabled={invalidForm}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<SendIcon />}
                    onClick={this.sendForm}
                  >
                    Confirm
                  </Button>
                </FormControl>
              </>
            )
          ) : (
            <>
              <Typography variant="h5" component="h5">
                The token provided was not conform or has expired.
              </Typography>
              <HighlightOffIcon className={classes.iconRefused} />
              <span role="presentation" onClick={this.goBack}>
                <span className={classes.spanGoBack}>Go back to login</span>
              </span>
            </>
          )}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(ResetPassword);
