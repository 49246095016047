import BaseAPI from './BaseAPI';

export default class AccountAPI extends BaseAPI {
  static Login(username, password, token) {
    return this.execute('post', '/accounts/login', {
      data: { username, password, token }
    });
  }

  static Register(firstname, lastname, username, password, token) {
    return this.execute('post', '/accounts', {
      data: {
        firstname,
        lastname,
        username,
        password,
        token
      }
    });
  }

  static validateResetPassword(token, password) {
    return this.execute('put', '/accounts/reset', {
      data: {
        token,
        password
      }
    });
  }

  static Logout() {
    return this.execute('delete', '/accounts/logout');
  }
}
