/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import { Notification } from 'element-react';
import { ReCaptcha } from 'react-recaptcha-v3';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconPortrait from '@material-ui/icons/Portrait';
import Joi from '@hapi/joi';
import styles from '../../styles/Login.css';
import { UserContext } from '../../contexts/UserContext';
import Configuration from '../../helpers/configuration';

const schema = Joi.object().keys({
  username: Joi.string()
    .label("L'adresse mail")
    .email({ tlds: false })
    .required(),
  password: Joi.string()
    .label('Le mot de passe')
    .min(5)
    .required(),
});

class Login extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      invalidForm: true,
      reCaptchaToken: '',
    };
  }

  captchaValidation = reCaptchaToken => {
    this.setState(
      { reCaptchaToken },
      () => this.recaptchaCallback && this.recaptchaCallback()
    );
  };

  handleChanges = (type, value) => {
    this.setState({ [type]: value }, this.checkForm);
  };

  resetPasswordHandleClick = () => {
    const { history } = this.props;
    history.push('/reset');
  };

  checkForm = () => {
    const { username, password } = this.state;
    const data = {
      username,
      password,
    };

    const result = schema.validate(data);
    this.setState({
      invalidForm: !!result.error,
    });
  };

  sendForm = e => {
    const { invalidForm, username, password, reCaptchaToken } = this.state;

    e.preventDefault();
    if (invalidForm) {
      return;
    }

    this.context
      .logUserIn(username, password, reCaptchaToken)
      .then(() => {
        this.context.getCurrentUser();
        this.props.history.push('/dashboard');
      })
      .catch(error => {
        Notification.error({
          title: 'Error',
          message: error.message,
        });
      });
  };

  render() {
    const { classes } = this.props;
    const { invalidForm } = this.state;
    return (
      <div className={classes.bodyContainer}>
        <div className={classes.loginContainer}>
          <div className={classes.accountHeader}>
            <IconPortrait className={classes.iconPortrait} />
            <h1 className={classes.loginTitle}>LOGIN</h1>
          </div>
          <form className={classes.loginForm} onSubmit={this.sendForm}>
            <div className={classes.accountContainer}>
              <TextField
                id="standard-email-input"
                label="Email"
                name="email"
                className={classes.textField}
                type="email"
                autoComplete="current-email"
                margin="normal"
                onChange={e => {
                  this.handleChanges('username', e.target.value);
                }}
              />
              <TextField
                id="standard-password-input"
                label="Password"
                name="password"
                className={classes.textField}
                type="password"
                autoComplete="current-password"
                margin="normal"
                onChange={e => {
                  this.handleChanges('password', e.target.value);
                }}
              />

              <ReCaptcha
                ref={ref => {
                  this.recaptcha = ref;
                }}
                sitekey={Configuration.CAPTCHA_TOKEN}
                verifyCallback={this.captchaValidation}
                action="login"
              />

              <Button
                disabled={invalidForm}
                variant="contained"
                color="primary"
                className={classes.buttonSend}
                type="submit"
                onClick={this.sendForm}
              >
                Login
              </Button>
            </div>
          </form>
          <div className={classes.accountFooter}>
            <span
              role="presentation"
              onClick={() => this.resetPasswordHandleClick()}
            >
              <span className={classes.spanSignUp}>Forgot your password</span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Login);
