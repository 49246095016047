/* eslint-disable import/no-unresolved */
import Joi from '@hapi/joi';
import React, { Component } from 'react';
import { Notification } from 'element-react';
import { ReCaptcha } from 'react-recaptcha-v3';
import { withStyles } from '@material-ui/core/styles';
import IconPortrait from '@material-ui/icons/Portrait';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from '../../styles/Registration.css';
import { UserContext } from '../../contexts/UserContext';
import Configuration from '../../helpers/configuration';

const schema = Joi.object().keys({
  firstname: Joi.string()
    .regex(/^[A-Za-zÀ-ÖØ-öø-ÿ]+$/u, 'name')
    .label('Firstname')
    .min(2)
    .max(30)
    .required(),
  lastname: Joi.string()
    .regex(/^[A-Za-zÀ-ÖØ-öø-ÿ]+$/u, 'name')
    .label('Lastname')
    .min(2)
    .max(30)
    .required(),
  username: Joi.string()
    .label('Email')
    .email({ tlds: false })
    .required(),
  password: Joi.string()
    .label('Password')
    .min(5)
    .required(),
  passwordVerif: Joi.string()
    .label('Password confirm')
    .required()
    .valid(Joi.ref('password'))
});

class Registration extends Component {
  static contextType = UserContext;

  state = {
    firstname: '',
    lastname: '',
    username: '',
    password: '',
    passwordVerif: '',
    invalidForm: true,
    error: '',
    reCaptchaToken: ''
  };

  captchaValidation = reCaptchaToken => {
    this.setState(
      { reCaptchaToken },
      () => this.recaptchaCallback && this.recaptchaCallback()
    );
  };

  handleChanges = (type, value) => {
    this.setState({ [type]: value }, this.checkForm);
  };

  checkForm = () => {
    const {
      firstname,
      lastname,
      username,
      password,
      passwordVerif
    } = this.state;
    const data = {
      firstname,
      lastname,
      username,
      password,
      passwordVerif
    };

    const result = schema.validate(data);
    this.setState({
      invalidForm: !!result.error,
      error: result.error ? result.error.details[0].message : null
    });
  };

  sendForm = e => {
    const {
      invalidForm,
      firstname,
      lastname,
      username,
      password,
      reCaptchaToken
    } = this.state;
    e.preventDefault();

    if (invalidForm) {
      return;
    }

    this.context
      .registerUser(firstname, lastname, username, password, reCaptchaToken)
      .then(async () => {
        // FIXME: Little hack here
        // Since the react-recaptcha-v3 lib doesn't return anything
        // We are forced to set a Promise yourself to handle captcha reset
        // see: https://github.com/codeep/react-recaptcha-v3/issues/2
        await new Promise(resolve => {
          this.recaptchaCallback = resolve;
          this.recaptcha.execute();
        });

        await this.context.logUserIn(username, password, this.state.reCaptchaToken);
        await this.context.getCurrentUser();
        this.props.history.push('/dashboard');
      })
      .catch(async error => {
        await this.recaptcha.execute();
        Notification.error({
          title: 'Error',
          message: error.message
        });
      });
  };

  loginHandleClick = () => {
    const { history } = this.props;
    history.push('/login');
  };

  formPreventDefault = e => {
    e.preventDefault();
  };

  render() {
    const { classes } = this.props;
    const { error, invalidForm } = this.state;
    let errorBanner;
    if (error) {
      errorBanner = (
        <div className={`${classes.notifError} el-alert el-alert--warning`}>
          <div className="el-alert__content">
            <span className="el-alert__title">{error}</span>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.bodyContainer}>
        <div className={classes.subscribeContainer}>
          <div className={classes.subscribeHeader}>
            <IconPortrait className={classes.iconPortrait} />
            <h1 className={classes.subscribeTitle}>SUBSCRIBE</h1>
            <p className={`${classes.conditions} conditions`}>
              Create your account and get a free, unlimited usage.
            </p>
          </div>
          <form className={classes.form} onSubmit={this.sendForm}>
            {errorBanner}
            <div className={classes.accountContainer}>
              <TextField
                id="standard-fisrtname-input"
                label="Firstname"
                className={classes.textField}
                type="text"
                name="firstname"
                autoComplete="current-firstname"
                margin="normal"
                placeholder="John"
                onChange={e => {
                  this.handleChanges('firstname', e.target.value);
                }}
              />
              <TextField
                id="standard-lastname-input"
                label="Lastname"
                className={classes.textField}
                type="text"
                name="lastname"
                autoComplete="current-lastname"
                margin="normal"
                placeholder="Smith"
                onChange={e => {
                  this.handleChanges('lastname', e.target.value);
                }}
              />
              <TextField
                id="standard-email-input"
                label="Email"
                className={classes.textField}
                type="text"
                name="email"
                autoComplete="current-email"
                margin="normal"
                placeholder="Johnsmith@gmail.com"
                onChange={e => {
                  this.handleChanges('username', e.target.value);
                }}
              />
              <TextField
                id="standard-password-input"
                label="Password"
                className={classes.textField}
                type="password"
                name="password"
                margin="normal"
                onChange={e => {
                  this.handleChanges('password', e.target.value);
                }}
              />
              <TextField
                id="standard-passwordverif-input"
                label="Password confirm"
                className={classes.textField}
                type="password"
                name="password_verif"
                margin="normal"
                onChange={e => {
                  this.handleChanges('passwordVerif', e.target.value);
                }}
              />

              <ReCaptcha
                ref={(ref) => { this.recaptcha = ref }}
                sitekey={Configuration.CAPTCHA_TOKEN}
                verifyCallback={this.captchaValidation}
                action='registration'
              />
            </div>

            <Button
              disabled={invalidForm}
              variant="contained"
              color="primary"
              className={classes.buttonSend}
              type="submit"
              onClick={this.sendForm}
            >
              Sign up
            </Button>
          </form>
          <div className={classes.subscribeFooter}>
            <span>Already have an account ?</span>
            <span role="presentation" onClick={this.loginHandleClick}>
              <span className={classes.spanLogIn}>Log in</span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Registration);
