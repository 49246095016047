import axios from 'axios';

class Configuration {
  constructor() {
    this.HOST = 'autoswagger.local.io';
    this.API_URL = 'http://autoswagger.local.io:3001';
    this.CAPTCHA_TOKEN = '6Lewq5IUAAAAAN-cpv-zPUGyeFXFwOuGGYDcRMqS';
    this.CAPTCHA_BUTTON_TOKEN = '6LebgfAUAAAAAGdo2kM2kQuiQgLn3KiD90sTrf3e';
    this.GRAVITEE_ENABLED = false;
    this.GRAVITEE_MANAGEMENT_UI_URL = 'http://gravitee.local.io:8084';
    this.axiosClient = null;
  }

  /**
   * Load the constants.json file from the public assets and create the axios client
   *
   * @returns Promise<Void>
   */
  load() {
    return axios.get(`${process.env.PUBLIC_URL}/constants.json`)
      .then(({ data }) => {
        Object.keys(data).forEach(key => {
          this[key] = data[key];
        })
      })
      .finally(() => {
        // Create axios client
        this.axiosClient = axios.create({
          baseURL: `${this.API_URL}/v1`,
          json: true,
          withCredentials: true
        });
      });
  }
}

export default new Configuration();
