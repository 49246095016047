import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import './index.css';
import App from './Components/App';
import Configuration from './helpers/configuration';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-140063417-2');
}

Configuration.load()
  .finally(() => {
    ReactDOM.render(<App />, document.getElementById('root'));
  })
