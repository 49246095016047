import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <>
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          </>
        )
      }
    />
  );
};

export default RestrictedRoute;
