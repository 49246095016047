import BaseAPI from './BaseAPI';

export default class TeamAPI extends BaseAPI {
  static getTeam(teamId) {
    return this.execute('get', `/teams/${teamId}`);
  }

  static getTeamOrganization(teamId) {
    return this.execute('get', `teams/${teamId}/organization`);
  }

  static createTeam(name, organizationId) {
    return this.execute('post', '/teams', {
      data: { name, organizationId }
    });
  }

  static deleteTeam(teamId) {
    return this.execute('delete', `/teams/${teamId}`);
  }

  static getMyTeams() {
    return this.execute('get', '/users/me/teams');
  }

  static getMembers(teamId) {
    return this.execute('get', `teams/${teamId}/members`);
  }

  static updateMember(teamId, username, level) {
    return this.execute('put', `/teams/${teamId}/members`, {
      data: {
        username,
        level
      }
    });
  }

  static addMember(teamId, username, level = 'MEMBER') {
    return this.execute('post', `/teams/${teamId}/members`, {
      data: {
        username,
        level
      }
    });
  }

  static deleteMember(teamId, username) {
    return this.execute('delete', `/teams/${teamId}/members`, {
      data: {
        username
      }
    });
  }
}
