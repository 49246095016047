/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CustomizedExpansionPanel from './ExpandInformation';

const expansionPanel = createMuiTheme({
  overrides: {
    MuiExpansionPanel: {
      root: {
        borderRadius: '0 !important',
      },
    },
  },
});

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#324157',
    color: theme.palette.common.white,
    fontSize: '18px',
    padding: '14px 10px 14px 16px !important',
  },
  root: {
    padding: '0px 10px 0px 0px',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

function createData(
  description,
  personalFeature,
  teamFeature,
  enterpriseFeature
) {
  return { description, personalFeature, teamFeature, enterpriseFeature };
}

const CheckIcon = (
  <CheckCircleIcon style={{ color: '#5fc241', fontSize: '1.8em' }} />
);

const rows = [
  createData(
    <CustomizedExpansionPanel
      title="Design and publish to portal"
      description="Ability to design any number of APIs, import/export openAPI specs, sharing on built-in dev portal."
    />,
    CheckIcon,
    CheckIcon,
    CheckIcon
  ),
  createData(
    <CustomizedExpansionPanel
      title="Collaboration"
      description="Share models with team members, with settable rights."
    />,
    <div />,
    CheckIcon,
    CheckIcon
  ),
  createData(
    <CustomizedExpansionPanel
      title="Organization templates (refcards)"
      description="Ensure coherence amongst resulting specifications: case, url, metadata, custom headers..."
    />,
    <div />,
    CheckIcon,
    CheckIcon
  ),
  createData(
    <CustomizedExpansionPanel
      title="Organization repositories"
      description="Ability to push models and specifications to an external Git repository."
    />,
    <div />,
    CheckIcon,
    CheckIcon
  ),
  createData(
    <CustomizedExpansionPanel
      title="Support"
      description="Access to the help ticketing system."
    />,
    <div />,
    CheckIcon,
    CheckIcon
  ),
  createData(
    <CustomizedExpansionPanel
      title="View profiles"
      description="Technical view with full details / Business view with low detail."
    />,
    <div />,
    <div />,
    CheckIcon
  ),
  createData(
    <CustomizedExpansionPanel
      title="Multi-teams"
      description="An organization can contain multiple teams sharing the same rules."
    />,
    <div />,
    <div />,
    CheckIcon
  ),
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {},
});

export default function CustomizedTables() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={expansionPanel}>
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Feature</StyledTableCell>
              <StyledTableCell align="center">Personal</StyledTableCell>
              <StyledTableCell align="center">Team</StyledTableCell>
              <StyledTableCell align="center">Enterprise</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {row.description}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.personalFeature}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.teamFeature}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.enterpriseFeature}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </ThemeProvider>
  );
}
